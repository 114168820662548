import React from "react";
import cn from "classnames";
import { Hexagon } from "../Hexagon";
import "./styles.css";

const ClassicSyrupsHeroSyrups = ({ className, maxWidth, syrups }) => {
  return (
    <div className={cn(className, "ClassicSyrupsHeroSyrups")}>
      <Hexagon className="ClassicSyrupsHeroSyrups__hexagon" />
      <ul
        className={cn(
          maxWidth,
          "[ ClassicSyrupsHeroSyrups__products ] [ Font_headerMobile Color_black sm:Font_mediumTextCaps ]"
        )}
      >
        {syrups.map(syrup => (
          <li key={syrup}>{syrup}</li>
        ))}
      </ul>
    </div>
  );
};

export { ClassicSyrupsHeroSyrups };

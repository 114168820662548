import cn from "classnames";
import React from "react";
import { Image } from "../../Image";
import "./styles.css";

const ClassicSyrupsHeroImage = ({ className, background, alt }) => {
  return (
    <div className={cn(className, "ClassicSyrupsHeroImage")}>
      <div className="ClassicSyrupsHeroImage__image">
        <Image image={background} alt={alt} />
      </div>
    </div>
  );
};

export { ClassicSyrupsHeroImage };

import { graphql } from "gatsby";
import React from "react";
import { ContactUs } from "../ContactUs";
import "./styles.css";

const ClassicSyrupsAdvantages = ({ advantages, formHeader, formBody }) => {
  return (
    <ContactUs
      className="ClassicSyrupsAdvantages"
      advantages={advantages}
      formHeader={formHeader}
      formBody={formBody}
    />
  );
};

ClassicSyrupsAdvantages.getLivePreviewData = ({ data, getAsset }) => ({
  classicSyrupsAdvantages: {
    ...data.classicSyrupsAdvantages,
  },
});

const classicSyrupsAdvantagesQuery = graphql`
  fragment ClassicSyrupsAdvantagesFragment on MarkdownRemarkFrontmatter {
    classicSyrupsAdvantages {
      advantages {
        title
        text
      }
      formHeader {
        title
        text
      }
    }
  }
`;

export { ClassicSyrupsAdvantages, classicSyrupsAdvantagesQuery };

import cn from "classnames";
import { graphql } from "gatsby";
import React from "react";
import { ClassicSyrupsHeroImage } from "./ClassicSyrupsHeroImage";
import { ClassicSyrupsHeroSyrups } from "./ClassicSyrupsHeroSyrups";
import "./styles.css";

const ClassicSyrupsHero = ({ className, maxWidth, background, alt, subtitle, title, syrups }) => {
  return (
    <div className={cn(className, "ClassicSyrupsHero")}>
      <div className="Content">
        <p className="Font_smallHeaderCaps sm:Font_mediumTextCaps Color_lightGrey">{subtitle}</p>
        <h1 className="[ ClassicSyrupsHero__title ] [ Font_bigHeaderSerif Color_black ]">{title}</h1>

        <ClassicSyrupsHeroSyrups className="ClassicSyrupsHero__syrups" maxWidth={maxWidth} syrups={syrups} />
      </div>

      <ClassicSyrupsHeroImage className="ClassicSyrupsHero__image" background={background} alt={alt} />
    </div>
  );
};

ClassicSyrupsHero.getLivePreviewData = ({ data, getAsset }) => ({
  classicSyrupsHero: {
    ...data.classicSyrupsHero,
    background: getAsset(data.classicSyrupsHero.background).url,
  },
});

const classicSyrupsHeroQuery = graphql`
  fragment ClassicSyrupsHeroFragment on MarkdownRemarkFrontmatter {
    classicSyrupsHero {
      background {
        childImageSharp {
          fluid(maxWidth: 2880, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      alt
      subtitle
      title
      syrups
    }
  }
`;

export { ClassicSyrupsHero, classicSyrupsHeroQuery };

import React from "react";
import Helmet from "react-helmet";
import { ClassicSyrupsAdvantages } from "../components/ClassicSyrupsAdvantages";
import { ClassicSyrupsHero } from "../components/ClassicSyrupsHero";
import { ClassicSyrupsOtherProducts } from "../components/ClassicSyrupsOtherProducts";
import { Layout } from "../components/Layout";

const ClassicSyrupsPageTemplate = ({ layout, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <ClassicSyrupsHero {...props.classicSyrupsHero} />
      <ClassicSyrupsAdvantages formBody={layout.formBody} {...props.classicSyrupsAdvantages} />
      <ClassicSyrupsOtherProducts {...props.classicSyrupsOtherProducts} />
    </>
  );
};

ClassicSyrupsPageTemplate.getLivePreviewData = props => ({
  ...Layout.getLivePreviewData(props),
  ...ClassicSyrupsHero.getLivePreviewData(props),
  ...ClassicSyrupsAdvantages.getLivePreviewData(props),
  ...ClassicSyrupsOtherProducts.getLivePreviewData(props),
});

const ClassicSyrupsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout {...data.layout.frontmatter}>
      <ClassicSyrupsPageTemplate layout={data.layout.frontmatter} {...frontmatter} />
    </Layout>
  );
};

const pageQuery = graphql`
  query ClassicSyrupsPageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
        formBody {
          policy
          buttonLabel
          successMessage
          successButtonLabel
          errorMessage
          errorButtonLabel
        }
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "classic-syrups" } }) {
      frontmatter {
        seo {
          title
          description
          keywords
        }
        ...ClassicSyrupsHeroFragment
        ...ClassicSyrupsAdvantagesFragment
        ...ClassicSyrupsOtherProductsFragment
      }
    }
  }
`;

export default ClassicSyrupsPage;

export { pageQuery, ClassicSyrupsPageTemplate };

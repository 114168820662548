import { graphql } from "gatsby";
import React from "react";
import { OtherProducts } from "../OtherProducts";
import "./styles.css";

const ClassicSyrupsOtherProducts = ({ title, products }) => {
  products = [
    { ...products[0], href: "/professional-syrups" },
    { ...products[1], href: "/honey" },
    { ...products[2], href: "/packaged-honey" },
  ];
  return <OtherProducts title={title} products={products} />;
};

ClassicSyrupsOtherProducts.getLivePreviewData = ({ data, getAsset }) => ({
  classicSyrupsOtherProducts: {
    ...data.classicSyrupsOtherProducts,
    products: {
      ...data.classicSyrupsOtherProducts.products,
      image: getAsset(data.classicSyrupsOtherProducts.products.image).url,
    },
  },
});

const classicSyrupsOtherProductsQuery = graphql`
  fragment ClassicSyrupsOtherProductsFragment on MarkdownRemarkFrontmatter {
    classicSyrupsOtherProducts {
      title
      products {
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        title
      }
    }
  }
`;

export { ClassicSyrupsOtherProducts, classicSyrupsOtherProductsQuery };
